<template>
    <div class="d-flex flex-column justify-center align-center fill-height py-8 overflow-hidden">
        <v-card v-if="!allInfoLoaded">
            <v-skeleton-loader type="card, list-item-two-line, actions" width="350"></v-skeleton-loader>
        </v-card>
        <div v-else class="cloud-wrapper">
            <v-card v-if="!sentEmail && validInvGen" width="350">
                <v-card-title class="justify-center align-center nuvolos-header">
                    <v-img max-width="200" max-height="22" class="py-5" src="@/assets/Nuvolos-transparent.svg" />
                </v-card-title>

                <h2 class="secondary--text text-center text-h6 mt-5" v-if="!isTrial">Join Nuvolos</h2>
                <h2 class="secondary--text text-center text-h6 mt-5" v-else>Create a Nuvolos account</h2>

                <v-card-text v-if="nuvolosEmail">
                    <p v-if="isTrial">{{ trialDescription }}</p>
                    <p v-else>
                        You will be able to join
                        <b>{{ spaceName }}</b>
                        (space) in
                        <b>{{ orgName }}</b>
                        (organization)
                    </p>
                    <v-card-actions>
                        <v-btn
                            class="primary"
                            elevation="0"
                            block
                            :loading="sendingInvitation || validatingEmails"
                            :disabled="combinedEmail === '' || validatingEmails || sendingInvitation || invalidGenerator"
                            @click="validateEmailAndSignUp(true)">
                            Next
                        </v-btn>
                    </v-card-actions>
                </v-card-text>

                <v-card-text v-else-if="loggedInEmail">
                    <p v-if="isTrial">{{ trialDescription }}</p>
                    <p v-else>You will be able to join {{ spaceName }} (space) in {{ orgName }} (organization)</p>
                    <v-form ref="form" v-model="valid" required @submit="validateEmailAndSignUp(true)" onSubmit="return false;">
                        <span v-if="!isGoogleOauth2" class="font-weight-bold">You will need to use the following email to log in to Nuvolos:</span>
                        <span v-else class="font-weight-bold">You will be able to log into nuvolos with the following google account:</span>
                        <div class="text-center my-4 font-weight-bold secondary--text">{{ loggedInEmail }}</div>
                        <v-btn
                            class="primary"
                            elevation="0"
                            block
                            :loading="sendingInvitation || validatingEmails"
                            :disabled="!valid || loggedInEmail === '' || validatingEmails || sendingInvitation || invalidGenerator"
                            type="submit">
                            {{ isGoogleOauth2 ? 'Request access to nuvolos' : 'Request sign up e-mail' }}
                        </v-btn>
                    </v-form>
                    <InvitationErrorsDialog
                        :showDialog="showInvitationErrorsDialog"
                        :errorType="validationErrorType"
                        :invalidEmails="invalidEmailsList"
                        @proceedWithTheInvitation="trySignUp($event.value)"
                        @validateEmailsAgain="validateEmailAndSignUp($event.value)"
                        :isSignUpDialog="true" />
                </v-card-text>

                <v-card-text v-else>
                    <ask-about-account :skipAsk="isTrial">
                        <template v-if="showEmailFields">
                            <p>Please provide your {{ isTrial ? '' : 'academic' }} email address below to receive a sign-up email</p>
                            <p v-if="isTrial">{{ trialDescription }}</p>
                            <p v-else>
                                You will be able to join
                                <b>{{ spaceName }}</b>
                                (space) in
                                <b>{{ orgName }}</b>
                                (organization)
                            </p>
                            <v-form ref="form" v-model="valid" required @submit="validateEmailAndSignUp(true)" onSubmit="return false;">
                                <v-text-field
                                    :rules="emailRules"
                                    v-model="email"
                                    required
                                    :disabled="invalidGenerator"
                                    :placeholder="`Your ${isTrial ? '' : 'academic '}e-mail address`"
                                    autofocus
                                    outlined
                                    dense></v-text-field>
                                <v-text-field
                                    v-model="emailValidate"
                                    required
                                    dense
                                    outlined
                                    :disabled="invalidGenerator"
                                    placeholder="Confirm your e-mail"></v-text-field>
                                <v-alert type="warning" text v-if="emailValidate !== '' && emailValidate !== email">Emails don't match</v-alert>
                                <div id="g-recaptcha" class="d-flex justify-center mb-5" ref="gr"></div>
                                <v-card-actions>
                                    <v-btn
                                        class="primary"
                                        elevation="0"
                                        block
                                        :loading="sendingInvitation || validatingEmails"
                                        :disabled="
                                            !valid ||
                                            email === '' ||
                                            validatingEmails ||
                                            sendingInvitation ||
                                            invalidGenerator ||
                                            emailValidate === '' ||
                                            email !== emailValidate
                                        "
                                        type="submit">
                                        Request sign up e-mail
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                            <InvitationErrorsDialog
                                :showDialog="showInvitationErrorsDialog"
                                :errorType="validationErrorType"
                                :invalidEmails="invalidEmailsList"
                                @proceedWithTheInvitation="trySignUp($event.value)"
                                @validateEmailsAgain="validateEmailAndSignUp($event.value)"
                                :isSignUpDialog="true" />
                        </template>

                        <template v-else>
                            <institution-login>
                                <v-btn @click="setNoSWITCH" text block>Sign up with email</v-btn>
                            </institution-login>
                        </template>
                    </ask-about-account>

                    <div class="text-caption mt-6">
                        By continuing, you agree to Nuvolos's
                        <a href="https://nuvolos.cloud/legal" target="_blank">Terms of Service</a>
                        and
                        <a href="https://nuvolos.cloud/privacy-policy" target="_blank">Privacy Policy</a>
                        , and to receiving emails with updates.
                    </div>
                </v-card-text>
            </v-card>

            <v-card v-else-if="validInvGen" width="350">
                <v-card-title>
                    <v-icon large color="primary" class="mr-2">mail</v-icon>
                    Verify your e-mail
                </v-card-title>
                <v-card-text class="pt-5">
                    <p>We have sent an invitation email to your email address</p>
                    <p class="text-center secondary--text">{{ combinedEmail }}</p>
                    <p>Please check your inbox and click on the link in the e-mail to create your account.</p>
                    <span class="d-block text-center caption">(you can close this page)</span>
                </v-card-text>
            </v-card>

            <v-card v-else width="500">
                <v-card-title class="justify-center align-center">
                    <v-avatar color="white">
                        <v-icon x-large color="error">error</v-icon>
                    </v-avatar>
                </v-card-title>
                <v-card-text class="font-weight-bold text-center">
                    Invalid link encountered, please check if you have the right {{ isTrial ? 'trial' : 'invitation' }} link.
                </v-card-text>
            </v-card>

            <img src="@/assets/cloud-1.svg" class="cloud cloud-1" />
            <img src="@/assets/cloud-3.svg" class="cloud cloud-3" />
        </div>
        <v-dialog :value="showError || invalidGenerator" max-width="600" :persistent="invalidGenerator">
            <v-card>
                <v-card-title>Sign-up error</v-card-title>
                <v-card-text>
                    <v-alert type="error" class="mt-2">{{ errorContent }}</v-alert>
                    <div class="mt-2">
                        If you continue to encounter issues,
                        <a @click="askForHelp">reach out to us</a>
                        .
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <invitation-modal :message="isTrial ? 'Creating your trial space' : 'Creating your instance'" :show="sendingInvitation" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { emailValidation } from '@/mixins/validateEmail'
import { isDateGreaterThanToday } from '@/utils'
import store from 'store'

const InvitationErrorsDialog = () => import('@/components/InvitationErrorsDialog')
const InstitutionLogin = () => import('@/modules/invitations/components/InstitutionLogin.vue')
const AskAboutAccount = () => import('@/modules/invitations/components/AskAboutAccount.vue')
const InvitationModal = () => import('@/modules/invitations/components/TheInvitationModal.vue')

export default {
    mixins: [emailValidation],
    components: { InvitationErrorsDialog, InstitutionLogin, AskAboutAccount, InvitationModal },
    data() {
        return {
            orgName: '',
            spaceName: '',
            email_domain_whitelist: [],
            showError: false,
            invalidGenerator: false,
            errorContent: 'An error has occured.',
            email: '',
            emailValidate: '',
            emailRules: [
                v =>
                    !this.email_domain_whitelist.length ||
                    this.email_domain_whitelist.some(wl => this.email.endsWith(wl)) ||
                    'Email must end with one of: @' + this.email_domain_whitelist.join(', @')
            ],
            matchValid: [v => v === this.email || "E-mails don't match"],
            valid: false,
            sentEmail: false,
            sendingInvitation: false,
            noAccount: false,
            nonSWITCH: false,
            loadingInvitation: true,
            validInvGen: true,
            isTrial: false,
            recaptchaToken: '',
            recaptchaOnloadHappened: false,
            recaptchaPlaceholderLoaded: false,
            trialDescription: '',
            trialSid: null
        }
    },
    created() {
        this.isTrial = this.$route.name === 'trials'
        if (this.isTrial) {
            this.getTrialData()
        } else {
            this.getInvitationData()
        }
    },
    methods: {
        async getTrialData() {
            try {
                const { data } = await this.$axios.get(`/trial/describe/${this.$route.params.token}`)
                this.trialDescription = data.trial_description
                this.trialSid = data.from_sid
            } catch (error) {
                console.log(error)
                this.validInvGen = false
            } finally {
                this.loadingInvitation = false
            }
        },
        async getInvitationData() {
            try {
                const response = await this.$axios.get(`/invitations/invgen/${this.$route.params.token}`)
                this.email_domain_whitelist = response.data.email_domain_whitelist
                this.orgName = response.data.org_long_id
                this.spaceName = response.data.space_long_id
                if (!response.data.is_valid && !isDateGreaterThanToday(response.data.validity_timestamp)) {
                    this.invalidGenerator = true
                    this.errorContent = 'This invitation generator has expired. Please contact your lecturer or TA to get a valid invitation'
                } else if (!response.data.is_valid) {
                    this.invalidGenerator = true
                    this.errorContent = 'This invitation generator has been exhausted. Please contact your lecturer or TA to get an invitation'
                }
                // this.isTrial = response.data.trid !== undefined && response.data.trid !== null
            } catch (error) {
                console.log(error)
                this.validInvGen = false
            } finally {
                this.loadingInvitation = false
                if (sessionStorage.getItem('nvSATOSALoginFailed') === 'failed') {
                    this.nonSWITCH = true
                }
            }
        },
        warning(event) {
            event.returnValue = ''
        },
        async trialSignup() {
            try {
                const { data } = await this.$axios.post(`/trial/start`, {
                    email: this.combinedEmail,
                    g_recaptcha_response: this.recaptchaToken,
                    from_sid: this.trialSid,
                    long_id: this.combinedEmail,
                    send_invitation: true
                })
                console.log('trial data:', data)
                this.sendingInvitation = false
                if (data.url && data.url !== 'null') {
                    this.$nextTick(() => {
                        const pathname = new URL(data.url).pathname
                        const type = this.$route.params.type ? `/${this.$route.params.type}` : ''
                        const url = `${window.location.origin}${pathname}${type}`
                        console.log('trial url:', url)
                        window.location.href = url
                    })
                } else {
                    this.sentEmail = true
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Sign-up request successfully sent!',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                }
            } catch (error) {
                console.log(error)
                if (error?.response?.data?.code === 'user_already_exists') {
                    this.errorContent = 'User already exists.'
                }
                if (error?.response?.data?.code === 'invalid_from_sid') {
                    this.errorContent = error?.response?.data?.reason
                }
                if (error?.response?.data?.code === 'email_validation_failed') {
                    this.errorContent = 'We could not verify your email as an academic e-mail address, please use the e-mail address of your institution.'
                }
                this.showError = true
            } finally {
                this.sendingInvitation = false
            }
        },
        async signup() {
            try {
                const { data } = await this.$axios.post(`/invitations/invgen/${this.$route.params.token}`, {
                    email: this.combinedEmail,
                    g_recaptcha_response: this.recaptchaToken
                })
                console.log('data:', data)
                if (data.url && data.url !== 'null') {
                    this.sendingInvitation = false
                    this.$nextTick(() => {
                        const pathname = new URL(data.url).pathname
                        const type = this.$route.params.type ? `/${this.$route.params.type}` : ''
                        const url = `${window.location.origin}${pathname}${type}`
                        console.log('url:', url)
                        window.location.href = url
                    })
                } else {
                    this.sentEmail = true
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Sign-up request successfully sent!',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                }
            } catch (error) {
                if (error?.response?.data?.code === 'create_instances_with_single_editor_failed') {
                    this.errorContent =
                        'A sign up request has already been sent to the provided email address, please check the email box or contact support@alphacruncher.com'
                } else if (error?.response?.data?.code === 'instance_already_exists') {
                    this.errorContent =
                        'An instance associated with this email address has already been created, please try to login to Nuvolos with this email.'
                    window.setTimeout(() => {
                        this.$router.push({ name: 'login', query: { login_hint: this.combinedEmail } })
                    }, 5000)
                } else if (error?.response?.data?.code === 'invitation_generator_has_expired') {
                    this.invalidGenerator = true
                    this.errorContent = 'This invitation generator has expired. Please contact your lecturer or TA to get a valid invitation'
                } else if (error?.response?.data?.code === 'email_validation_failed') {
                    this.errorContent = 'We could not verify your email as an academic e-mail address, please use the e-mail address of your institution.'
                } else {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Sign-up request failed',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                }

                this.showError = true
            } finally {
                this.sendingInvitation = false
            }
        },
        trySignUp(confirmed) {
            if (confirmed) {
                this.showError = false
                this.sendingInvitation = true
                store.set('nvLastEmail', this.combinedEmail)
                if (this.showEmailFields) {
                    this.recaptchaToken = window.grecaptcha.getResponse()
                    if (this.recaptchaToken === '') {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Please verify that you are not a robot.',
                            snackBarTimeout: 5000,
                            snackBarIcon: 'error'
                        })
                        this.sendingInvitation = false
                        return
                    }
                }

                console.log('combinedEmail:', this.combinedEmail)
                console.log('recaptchaToken:', this.recaptchaToken)

                if (this.isTrial) {
                    this.trialSignup()
                } else {
                    this.signup()
                }
            }
        },
        validateEmailAndSignUp(confirmed) {
            if (confirmed) {
                this.showError = false
                this.validateEmailsWithPublicMailgunApi(this.combinedEmail).finally(() => {
                    if (!this.emailsValidationError && !this.foundInvalidEmails && this.emailValidatedWithPublicAPI) {
                        this.trySignUp(confirmed)
                    }
                })
            }
        },
        setNoSWITCH() {
            this.nonSWITCH = true
        },
        askForHelp() {
            window.Intercom('showNewMessage', 'Hi Nuvolos Team, Please reach out to me using the above email regarding the following issue:')
        },
        loadRecaptcha() {
            window.grecaptcha.render('g-recaptcha', {
                sitekey: '6LfHPpIbAAAAAKOYkCh6CHyunO5qiCf73Gx5Ywbj'
            })
        }
    },
    computed: {
        ...mapState(['userInfo', 'fetchingUserInfo']),
        validationErrorType() {
            if (this.foundInvalidEmails) {
                return this.emailValidationErrorTypes.INVALID_EMAILS_ERROR
            } else if (this.backendError || this.emailsValidationError) {
                return this.emailValidationErrorTypes.EMAIL_VALIDATION_BACKEND_ERROR
            }
            return null
        },
        showInvitationErrorsDialog() {
            if (this.foundInvalidEmails || this.backendError || this.emailsValidationError) {
                return true
            }
            return false
        },
        loggedInEmail() {
            return this.$auth.user && this.$auth.user.email ? this.$auth.user.email : null
        },
        nuvolosEmail() {
            return this.userInfo ? this.userInfo.email : null
        },
        combinedEmail() {
            return this.nuvolosEmail || this.loggedInEmail || this.email
        },
        showEmailFields() {
            return !this.nuvolosEmail && (this.nonSWITCH || (this.$auth.isAuthenticated && !this.loggedInEmail))
        },
        allInfoLoaded() {
            if (!this.loadingInvitation && !this.fetchingUserInfo && !this.$auth.loading) {
                return true
            }
            return false
        },
        // skipTrial() {
        //     return this.isTrial && this.userInfo && this.userInfo.uid
        // },
        recaptchaReady() {
            return this.recaptchaOnloadHappened && this.recaptchaPlaceholderLoaded
        },
        isGoogleOauth2() {
            return this.$route.params.type === 'google'
        }
    },
    watch: {
        showEmailFields: {
            handler: function (to) {
                if (to) {
                    const recaptchaScript = document.createElement('script')
                    recaptchaScript.setAttribute('src', 'https://www.recaptcha.net/recaptcha/api.js?onload=recaptchaOnloadCallback&render=explicit')
                    document.head.appendChild(recaptchaScript)
                    window.recaptchaOnloadCallback = () => {
                        this.recaptchaOnloadHappened = true
                    }
                }
            }
        },
        // skipTrial: {
        //     handler: function(to) {
        //         if (to) {
        //             this.$router.push({ name: 'welcome' })
        //         }
        //     },
        //     immediate: true
        // },
        recaptchaReady(ready) {
            if (!ready) return
            this.loadRecaptcha()
        }
    },
    updated() {
        if (this.$refs.gr) this.recaptchaPlaceholderLoaded = true
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/signup.scss';
</style>
